<template>
	<div class="banner-container">
		<div class="inner"></div>
	</div>
</template>

<script>
export default {
	name: "Banner"
}
</script>

<style scoped lang="scss">
.banner-container {
	width: 100%;
	height: 20vw;

		.inner {
			background-image: url('../../assets/img/photo_search.jpg');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 100%;
			width: 100%;
		}
}

</style>
