<template>
	<div class="footer-container centering">
		<div class="info-container centering">
			<!-- <div class="logo"><img src="@/assets/img/logo_white.png" alt=""></div> -->
				<h4 style="color: #ffffff; font-size: 14px">Επικοινωνία</h4>

			<ul class="info">
				<!-- <li>
					Πραξιτέλους 169 & Μπουμπουλίνας, 185 35 Πειραιάς
				</li>
				<li>
					T: 216 9003700, F: 210 4296024
				</li> -->
				<li style="font-weight:bold">
					<a style="color:#fff" href="mailto:info.bg@gmail.com">info.bg@gmail.com</a>
				</li>
			</ul>
			<!-- <div class="divider"></div>
			<div class="terms-link" @click="$router.push('/terms-of-use')"> Οδηγίες Χρήσης </div> -->
		</div>
		<div class="pavla">© copyright {{ new Date().getFullYear() }} Α. Οικονομόπουλος All Rights Reserved. <br> Developed by <a href="https://www.pavla.gr" target="_blank">Pavla S.A.</a> </div>
	</div>
</template>

<script>

export default {
	name: "AppFooter"
}
</script>

<style scoped lang="scss">
	.footer-container {
		width: 100%;

		.logo {
			// background-image: url('../assets/img/logo_white.png');
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin-bottom: 1%;

			img {
				max-width: 50%;
				margin-bottom: 3%;
			}
		}

		.info-container {
		padding: 2% 0;
			background-color: #10161e;
			width: 100%;
		}

		.info {
			list-style-type: none;
			color: #fff;
			margin-top: 0.5rem;

			li {
				margin: 2% 0;
			}
		}
	}

	.pavla {
		background-color: #f4f1e9;
		width: 100%;
		color: #000;
		font-family: 'Roboto', sans-serif!important;
		padding: 2% 0;
		font-size: 11px;

		a {
		font-family: 'Roboto', sans-serif!important;
			color: $orange!important;
		}
	}

		.divider {
		width: 50px;
		border-bottom: 1px solid $grey;
		margin: 2% 0 2% 0;
	}

	.terms-link {
		color: #fff;
		cursor: pointer;
	}

	.terms-link:hover {
		text-decoration: underline;
		text-shadow: 1px 0 #fff;
	}

	@media(max-width: 560px) {
		.logo {
			img {
				max-width: 50%;
				margin-bottom: 3%;
			}
		}

		.info {
			padding: 3%;
		}
	}

	.footer-container .info-container {
		padding: 2.5% 0;
	}

</style>
