<template>
	<div class="hero-container">
			<div class="main-content">
				<div class="hero-text">
					<h1>Θησαυρός της ελληνικής γραμματείας</h1>
				</div>
				<div class="hero-banner centering">
					<!-- <img class="md-elevation-10" src="@/assets/img/photo_slider.jpg" alt=""> -->
					<div class="inner">
					<hero-slider/>
					</div>
				</div>
				<div class="hero-text">
					<h2>Ο Θησαυρός της ελληνικής γραμματείας <br> από την συλλογή βιβλίων του <br> Αθανασίου Οικονομόπουλου</h2>
				</div>
			</div>
	</div>
</template>

<script>
import HeroSlider from '@/components/ui/HeroSlider.vue'

export default {
	name: "Hero",
	components: { HeroSlider }
}
</script>

<style lang="scss" scoped>
.hero-container {
	background-image: url('../assets/img/bg_1.jpg');
	background-size: cover;
	padding: 18px 0;
	display: flex;
	background-repeat: repeat;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.hero-text {
	margin: 2rem 0;

	h1, h2 {
		font-size: calc(14px + (54 - 14) * ((100vw - 300px) / (1600 - 300)));
		color: $brown;
		font-weight: 100;

	}

	h1 {
		margin-bottom: 3rem;
	}

	h2 {
		font-size: calc(14px + (36 - 14) * ((100vw - 300px) / (1600 - 300)));
		line-height: 1.3;
		padding: 0 21%
	}
}

.hero-banner {
	padding: 0%;
	padding: 1rem 21%;

	img {
		// width: 60%;
	}

	.inner{
		background-image: url('../assets/img/photo-1.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position-x: center;
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.35), 0 10px 14px 1px rgba(0, 0, 0, 0.35), 0 4px 18px 3px rgba(0,0,0,.35);
		height: 28vw;
		width: 100%;
		max-width: 1920px;
		display: flex;
		justify-content: space-around;
	}
}

.md-elevation-10 {
    box-shadow: 0 6px 6px -3px rgba(0,0,0,.35), 0 10px 14px 1px rgba(0, 0, 0, 0.35), 0 4px 18px 3px rgba(0,0,0,.35);
}

@media(max-width: 1450px) {
}
</style>
