<template>
<div class="slider-root">
	<div class="btn-left">
		<img @click="prvSlide()" class="md-elevation-10" src="@/assets/img/left.png" alt="">
	</div>
<!-- Make a div wrapped slider,set height and width -->
	<div style="width:90%;margin:20px auto;height:400px">
		<!-- Using the slider component -->
			<slider ref="slider" :options="options" @slide='slide' @init='onInit'>
				<!-- slideritem wrapped package with the components you need -->
				<slideritem v-for="(item,index) in imgList" :key="index" :style="style">
					<img :src="imgUrl(item)" alt="" class="slide-img">
				</slideritem>
				<!-- Customizable loading -->
				<div slot="loading">loading...</div>
			</slider>
	</div>
	<div class="btn-right">
		<img @click="nxtSlide()" class="md-elevation-10" src="@/assets/img/right.png" alt="">
	</div>
</div>

</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'
export default {
	name: 'HomeSlider',
	components: { slider, slideritem },
	data () {
		return {
			// data list [array]
			imgList: [
				{
					path: `Aeschines_British_Museum`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Corinth_Periander_Vaticano`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Herodotos_Metropolitan`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Anisthenes_Pio-Clementino`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Apollodorus_Damascus_Glyptotek`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Bias_villa_Cassius`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Pittacos_Louvre`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Pythagoras_Borghese`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Pytheas`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				},
				{
					path: `Sappho_Musei_Capitolini`,
					style: {
						'background': 'transparent'
						// 'height': '100%'
					}
				}
			],
			// Slider configuration [obj]
			options: {
				currentPage: 0,
				infinite: 0,
				slidesToScroll: 1,
				loop: true,
				pagination: false,
				loopedSlides: 1,
				autoplay: 2500
			},
			style: {
				'background': 'transparent',
				'width': '33%',
				'height': '100%',
				'margin-right': '2%'
			}
		}
	},
	methods: {
		imgUrl(i) {
			const images = require.context('../../assets/img', false, /\.png$/)
			// console.log(images('./' + i.path + ".png"))
			return images('./' + i.path + ".png")
		},

		slide(obj) {
			// console.log('slide:', obj)
		},
		onInit(data) {
			// console.log('init:', data)
		},
		nxtSlide() {
			// console.log(typeof this.$refs.slider.data.currentPage)
			this.$refs.slider.$emit('slideNext')
		},
		prvSlide() {
			this.$refs.slider.$emit('slidePre')
		}
	}

}
</script>

<style lang="scss" scoped>
	.slider-root {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.btn-left {
		margin-left: 1rem;
	}

	.btn-right {
		margin-right: 1rem;
	}

	.slide-img {
		max-height: 100%!important;
		height: 24vw;
	}

</style>
