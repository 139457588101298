import store from '../index'
import axios from 'axios'

// const searchLink = "search?limit=10&"

const state = {
	search_books: [],
	result_pages: null,
	result_count: null,
	queryUrl: ''
}

function createUrl(newData) {
	let url =
		new URL(`${store.state.rootUrl}search?limit=${store.state.limit}&page=${store.state.apiPage}`)
	Object.keys(newData).forEach((key) => {
		if (newData[key] !== null && newData[key] !== "") {
			url.searchParams.append(`${key}`, `${newData[key]}`)
		}
	})
	// handle input in greek
	url = decodeURI(url)
	return url
}

const actions = {
	searchData({ commit }, payload) {
		let searchUrl = createUrl(payload)
		store.state.loading = true
		commit('setQueryUrl', payload)
		// console.log(searchUrl)
		axios.get(`${searchUrl}`)
			.then((response) => {
				store.state.loading = false
				// console.log(response.data.data)
				commit('updateResults', response.data.data)
				// commit('clearSearch')
			})
	}
}

const mutations = {
	updateResults(state, newData) {
		state.search_books = newData.books
		state.result_pages = newData.pages
		state.result_count = newData.count
	},
	setQueryUrl(state, newUrl) {
		state.queryUrl = newUrl
	}
}

const getters = {
	getSearchBooks(state) {
		return state.search_books
	},
	getResultPages(state) {
		return state.result_pages
	},
	getResultCount(state) {
		return state.result_count
	}
}

export default {
	state,
	actions,
	mutations,
	getters
}
