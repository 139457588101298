<template>
	<div class="the-header">
		<!-- <SideNavToggle @toggle="$emit('SideNavToggle') " /> -->
		<div class="logo-container">
				<router-link to="/" exact=""><div class="inner" /></router-link>
		</div>
		<div class="menu-container">
			<ul class="nav-list">
				<li>
				<router-link v-scroll-to="'#collection'" to="/" exact="">
					Η συλλογή Α. Οικονομόπουλου
				</router-link>
				</li>
				<li v-scroll-to="'#homeSearch'">
					<router-link to="/search" exact="">
						Αναζήτηση
					</router-link>
				</li>
				<li>
					<router-link to="/contact" exact="">
						Επικοινωνία
					</router-link>
				</li>
			</ul>
		</div>
		<div v-if="false" class="search-container">
			<!-- <input class="simple-input" type="text" placeholder="ΑΝΑΖΗΤΗΣΗ" />
			<md-icon style="color:rgba(0, 0, 0, 0.85)">search</md-icon> -->
			<md-field md-clearable>
				<label>ΑΝΑΖΗΤΗΣΗ</label>
				<md-input placeholder=""></md-input>
			</md-field>
		</div>
	</div>
</template>

<script>
export default {
	name: "AppHeader",
	components: {
	}
}
</script>

<style scoped lang="scss">

	.the-header {
		display: flex;
		padding: 10px 20px;
		width: 100%;
		justify-content: space-around;

		ul {
			display: flex;
			list-style: none;
			max-width: 90%;
			min-width: 80%;

			a {
				white-space: nowrap;
			}
		}

	}

	.logo-container .inner {
		background-image: url("../../assets/img/logo.png");
		background-repeat: no-repeat;
		background-size: contain !important;
		padding: 10%;
		width: 26rem;
		z-index: 1;
		background-position-x: center;
	}

	.menu-container {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: flex-end;
	}

	.search-container {
		margin: auto;
		flex-grow: 1;
		justify-self: flex-end;
		justify-content: center;
    display: flex;

		.md-field {
			width: 33%;
		}

		.md-field.md-theme-default.md-has-value .md-input {
			-webkit-text-fill-color: #000;
		}

		.md-field.md-theme-default.md-focused label {
			color: #000;
		}

		.md-field.md-theme-default:after {
			background-color: $grey;
		}

		input:focus {
			color: $orange;
			border-color: $grey;
			outline-width: 0px;
		}

	}

	.nav-list {
		margin: auto 5% auto 0;
		padding: 0;
		width: 60%;
		justify-content: flex-end;

		li {
			font-size: 20px;
			font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
			margin: 0 2%;
			// margin-left: 3%;
		}
	}

@media (max-width: 1450px) {

	.logo-container .inner {
		width: 20rem
	}

	.nav-list {
		width: 100%;

		li {
				font-size: 17px;
			}
	}

	.search-container input {
		height: 38px;
	}
}

@media (max-width: 1200px) {
	.logo-container .inner {
		width: 14rem
	}

}
</style>
